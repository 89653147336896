const colors = [
    {
        id: 0,
        name: 'blue',
        backgroundColor: 'rgba(63, 103, 240, 0.2)',
        borderColor: 'rgba(63, 103, 240, 1)',
        borderWidth: 1,
    },
    {
        id: 1,
        name: 'yellow',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
    },
    {
        id: 2,
        name: 'skyblue',
        backgroundColor: 'rgba(119, 226, 223, 0.2)',
        borderColor: 'rgba(119, 226, 223, 1)',
        borderWidth: 1,
    },
    {
        id: 3,
        name: 'purple',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
    },
    {
        id: 4,
        name: 'orange',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
    },
    {
        id: 5,
        name: 'red',
        backgroundColor: 'rgba(233, 52, 52, 0.2)',
        borderColor: 'rgba(233,52,52,1)',
        borderWidth: 1,
    },
    {
        id: 6,
        name: 'lightgreen',
        backgroundColor: 'rgba(70, 254, 55, 0.2)',
        borderColor: 'rgba(70,254,56,1)',
        borderWidth: 1,
    },
    {
        id: 7,
        name: 'green',
        backgroundColor: 'rgba(74, 155, 63, 0.2)',
        borderColor: 'rgba(27,70,9,1)',
        borderWidth: 1,
    },
    {
        id: 8,
        name: 'gray',
        backgroundColor: 'rgba(200, 200, 200, 0.2)',
        borderColor: 'rgba(200,200,200,1)',
        borderWidth: 1,
    },
    {
        id: 9,
        name: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 1,
    },
    {
        id: 10,
        name: 'pink',
        backgroundColor: 'rgba(245, 183, 177, 0.2)',
        borderColor: 'rgba(245,183,177,1)',
        borderWidth: 1,
    },
    {
        id: 11,
        name: 'gold',
        backgroundColor: 'rgba(198, 193, 55, 0.2)',
        borderColor: 'rgba(198,193,55,1)',
        borderWidth: 1,
    },
    {
        id: 12,
        name: 'brown',
        backgroundColor: 'rgba(90, 60, 26, 0.2)',
        borderColor: 'rgba(90,60,26,1)',
        borderWidth: 1,
    },
    {
        id: 13,
        name: 'violet',
        backgroundColor: 'rgba(173, 35, 129, 0.2)',
        borderColor: 'rgba(173,35,129,1)',
        borderWidth: 1,
    },
    {
        id: 14,
        name: 'lime',
        backgroundColor: 'rgba(165, 173, 10, 0.2)',
        borderColor: 'rgba(165,173,10,1)',
        borderWidth: 1,
    },
    {
        id: 15,
        name: 'board',
        backgroundColor: 'rgba(100, 11, 6, 0.2)',
        borderColor: 'rgba(100,11,6,1)',
        borderWidth: 1,
    },
    {
        id: 16,
        name: 'fuchsia',
        backgroundColor: 'rgba(248, 52, 235, 0.2)',
        borderColor: 'rgba(248,52,235,1)',
        borderWidth: 1,
    },
    {
        id: 17,
        name: 'dark blue',
        backgroundColor: 'rgba(33, 42, 114, 0.2)',
        borderColor: 'rgba(33, 42, 114, 1)',
        borderWidth: 1,
    },
]



export const getColorByIndex = (i = 0) => {
    let color = colors[i]
    if (!color) {
        // selecciono un color random
        color = colors[Math.floor(Math.random() * colors.length )]
    }
    return color
}

export const getColorByName = (name = 'white') => {
    return colors.filter(c => c.name === name)[0] ? colors.filter(c => c.name === name)[0].borderColor : name
}

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}