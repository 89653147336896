import React, { useEffect, useState } from 'react'
import { getBusinessInfo, updateBusinessInfo } from '../firebase/obj/businessInfo'
import BusinessInfoForm from '../components/businessInfoForm'
import BusinessInfo from '../components/businessInfo'
import { CircularProgress } from '@material-ui/core'
import { useMainContext } from '../hooks/useMainContext'

export const BusinessInfoWithData = ({ publicData }) => {
    const [businessInfo, setBusinessInfo] = useState({})
    const [loading, setLoading] = useState(true)
    const { isAdmin } = useMainContext()

    const handleBusinessInfo = (e) => {
        // console.log('handleBusinessInfo', businessInfo)
        return updateBusinessInfo(businessInfo)
    }

    const loadBusinessInfo = () => {
        getBusinessInfo()
            .then(data => {
                setBusinessInfo(data)
            })
            .catch(setBusinessInfo(null))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        return loadBusinessInfo()
    }, [])

    if (loading) {
        return <CircularProgress style={{ color: '#EC441B' }} />
    }

    if (publicData) {
        return <BusinessInfo businessInfo={businessInfo} />
    }

    if (!isAdmin) {
        return <BusinessInfo businessInfo={businessInfo} />
    }

    return (
        <BusinessInfoForm businessInfo={businessInfo} updateInfo={handleBusinessInfo} setInfo={setBusinessInfo} />
    )
}

export default BusinessInfoWithData