import React, { createContext, useEffect, useState } from 'react'
import { getZonesRealTime, addZone, updateZone, deleteZone } from '../firebase/obj/zones'
import useMainContext from '../hooks/useMainContext'

export const ZoneContext = createContext()

const Provider = ({ children }) => {
    const [zones, setZones] = useState([])
    const { isAuth } = useMainContext()

    useEffect(() => {
        if (!isAuth) return
        const unsubscribe = getZonesRealTime(setZones)
        return () => unsubscribe()
    }, [isAuth])

    const handleSaveZone = async (zone) => {
        try {
            // console.log('handleSaveZone', zone)
            if (!isAuth) return
            if (zone.id) {
                return await updateZone(zone)
            } else {
                return await addZone(zone)
            }
        } catch (error) {
            console.error('saveZone', error)
        }
    }

    const handleDeleteZone = async (id) => {
        try {
            if (!isAuth) return
            return await deleteZone(id)
        } catch (error) {
            console.error('deleteZone', error)
        }
    }


    const value = {
        zones,
        saveZone: handleSaveZone,
        deleteZone: handleDeleteZone
    }

    return (
        <ZoneContext.Provider value={value}>
            {children}
        </ZoneContext.Provider>
    )
}

const context = {
    Provider,
    Consumer: ZoneContext.Consumer
}

export default context