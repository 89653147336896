import React from 'react'
import OrderWithData from '../containers/orderWithData'
import Context from '../context/mainContext'


export const OrderPage = ({ id }) => {
    // console.log('OrderPage', id)
    return (
        <Context.Consumer>
            {
                ({ user, config, isAdmin }) =>
                    <OrderWithData user={user} id={id} config={config} isAdmin={isAdmin} />
            }
        </Context.Consumer>
    )
}

export default OrderPage