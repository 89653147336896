import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
    },
    listContainer: {
        paddingBottom: 15,
        overflow: 'auto'
    },
    gridActions: {
        width: '100%',
    },
    card: {
        margin: 5
    },
    btnForm: {
        maxWidth: 80
    }
}))

export default useStyles