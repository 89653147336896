import React, { Fragment } from 'react'
import ListOfProductsWithData from '../containers/listOfProductsWithData'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
}))

export const ProductsPagePublic = () => {
  const classes = useStyles()

  return (
    <Fragment>
      <Grid container item className={classes.root} spacing={1} xs={12}>
        <ListOfProductsWithData publicData={true} />
      </Grid>
    </Fragment>
  )
}

export default ProductsPagePublic