import React from 'react'
import { Marker } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

L.Icon.Default.imagePath =
    '../node_modules/leaflet'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const greenMarker = new L.Icon({
    iconUrl: require('../../img/markerGreen.png'),
    iconSize: L.Icon.Default.prototype.options.iconSize,
    iconAnchor: L.Icon.Default.prototype.options.iconAnchor,
    popupAnchor: L.Icon.Default.prototype.options.popupAnchor,
})

const blueMarker = new L.Icon({
    iconUrl: require('../../img/markerBlue.png'),
    iconSize: L.Icon.Default.prototype.options.iconSize,
    iconAnchor: L.Icon.Default.prototype.options.iconAnchor,
    popupAnchor: L.Icon.Default.prototype.options.popupAnchor,
})

const defaultMarker = new L.Icon({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: L.Icon.Default.prototype.options.iconSize,
    iconAnchor: L.Icon.Default.prototype.options.iconAnchor,
    popupAnchor: L.Icon.Default.prototype.options.popupAnchor,
})

export const CustomMarker = (props) =>
    props.position ?
        <Marker styles={{ zIndex: 100 }} icon={(props.isAdmin && props.order.orderState === 'Finalizado') ? (props.order.pickup ? blueMarker : greenMarker) : defaultMarker} {...props} >
            {props.children}
        </Marker>
        :
        <div />

export default CustomMarker