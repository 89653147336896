import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: '75vh'
  },
}))


export default function DefaultList({ checkList, items = [], setSelected = () => { }, createSecondary = () => { } }) {
  const classes = useStyles()
  const [selectedItem, setSelectedItem] = React.useState('')

  const handleListItemClick = (event, item) => {
    setSelectedItem(item.id);
    setSelected(item)
  }


  return (
    <List dense className={classes.root}>
      {
        items.map(item => {
          let description = item.sale ? `${item.name} (EN OFERTA)` : item.name
          if (item.interfaceCode) {
            description = `${item.interfaceCode} - ${description}`
          }
          return (
            <ListItem key={item.id} button selected={selectedItem === item.id} onClick={(event) => handleListItemClick(event, item)}>
              {
                checkList &&
                <ListItemIcon>
                  <Checkbox
                    checked={item?.checked || false}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                  />
                </ListItemIcon>
              }

              <ListItemAvatar>
                <Avatar
                  alt={`BEFE BURGERS`}
                  src={item.src ? item.src : item.img}
                />
              </ListItemAvatar>
              <Grid item xs={9}>
                <ListItemText id={item.id} primary={description} secondary={createSecondary(item)} />
              </Grid>
              <Grid item container xs={3} justify="flex-end" alignItems="flex-start"  >
                <Grid item xs={12}>
                  <Typography variant='p'>{`$ ${item.price}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='p' color={item.stock ? 'textPrimary' : 'error'} >{`Stock: ${Number(item.stock || 0)}`}</Typography>
                </Grid>
              </Grid>

              {/* <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle(item.id)}
                                    checked={checked.indexOf(item.id) !== -1}
                                    inputProps={{ 'aria-labelledby': item.id }}
                                />
                            </ListItemSecondaryAction> */}
            </ListItem>
          )
        })
      }
    </List>
  )
}
