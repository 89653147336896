//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { detectDeviceType } from './detectNavigator'


export const saveError = (error, method = '') => {
    try {
        const { code = '', message = '' } = error || {}

        return new Promise(async (resolve, reject) => {
            const config = await firebase.app().firestore().collection("config").doc("config").get()

            if(!config?.logErrors)
                return resolve(error)

            firebase.auth().onAuthStateChanged(u => {
                if (code === 'permission-denied')
                    return resolve(error)

                const err = {
                    code,
                    message,
                    date: new Date(),
                    device: detectDeviceType(),
                    user: u ? u.email : '',
                    method
                }

                firebase.app().firestore().collection("errors")
                    .doc()
                    .set(err)
                    .then(resolve(error))
                    .catch(e => reject(e))
            })
        })
    } catch (error) {
        console.error(method, error)
    }
    return error
}