//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import 'firebase/compat/storage'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'

export default class Product {
  constructor() {
    this.name = ''
    this.price = 0
    this.sale = false
    this.notes = ''
    this.ingredients = ''
    this.category = ''
    this.active = true
    this.sort = 0
    this.img = 'https://freeiconshop.com/wp-content/uploads/edd/burger-outline-filled.png'
    this.composition = []
  }
}


export const getProductsRealTime = (setState = () => { }) => {
  return firebase.app().firestore().collection('products')
    .orderBy('sort', 'asc')
    .onSnapshot(querySnapshot => {
      setState(snapshotToArray(querySnapshot.docs))
    })
}


export const getProducts = () => {
  return new Promise((resolve, reject) => {
    firebase.app().firestore().collection('products')
      .where('active', '==', true)
      .orderBy('sort', 'asc')
      .get()
      .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
      .catch(error => reject(saveError(error, 'getProducts')))
  })
}

export const getAllProducts = () => {
  return new Promise((resolve, reject) => {
    firebase.app().firestore().collection('products')
      .orderBy('sort', 'asc')
      .get()
      .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
      .catch(error => reject(saveError(error, 'getAllProducts')))
  })
}

export const saveProduct = (product) => new Promise((resolve, reject) => {
  product.id ?
    firebase.app().firestore().collection('products').doc(product.id).update({
      ...product
    })
      .then(resolve('success'))
      .catch(error => reject(saveError(error, 'saveProduct > update')))
    :
    firebase.app().firestore().collection('products').add({
      ...product
    })
      .then(docRef => {
        firebase.app().firestore().collection('products').doc(docRef.id).update({
          ...product,
          id: docRef.id
        })
          .then(resolve('success'))
          .catch(error => reject(saveError(error, 'saveProduct > updateId')))
      })
      .catch(error => reject(saveError(error, 'saveProduct > add')))
})

export const updateProduct = (product) => new Promise((resolve, reject) =>
  firebase.app().firestore().collection('products').doc(product.id).update({
    ...product
  })
    .then(resolve('success'))
    .catch(error => reject(saveError(error, 'updateProduct'))))

export const deleteProduct = (prodId) => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('products').doc(prodId).delete()
    .then(resolve('Producto borrado'))
    .catch(error => reject(saveError(error, 'deleteProduct')))
})

function delay(t, v) {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t)
  })
}

function keepTrying(triesRemaining, storageRef) {
  if (triesRemaining < 0) {
    return Promise.reject('out of tries');
  }

  return storageRef.getDownloadURL().then((url) => {
    return url;
  }).catch((error) => {
    switch (error.code) {
      case 'storage/object-not-found':
        return delay(2000).then(() => {
          return keepTrying(triesRemaining - 1, storageRef)
        })
      default:
        console.log(error);
        return Promise.reject(error);
    }
  })
}

export const saveImage = (file, setUrl = () => { }, updateProgress = () => { }) => {
  const imgName = parseInt(new Date().valueOf() * Math.random())
  const refStorage = firebase.storage().ref(`products/${imgName}`)
  const refStorageResized = firebase.storage().ref(`products/resized/${imgName}_300x300`)
  const task = refStorage.put(file)
  task.on('state_changed',
    snapshot => {
      const porcentaje = snapshot.bytesTransferred / snapshot.totalBytes * 100
      updateProgress(porcentaje)
    },
    err => {
      console.error(saveError(err, 'saveImage'))
    },
    () => {
      task.snapshot.ref.getDownloadURL()
        .then(url => {
          //setUrl(url)
          updateProgress(99)
          keepTrying(10, refStorageResized)
            .then((url) => setUrl(url))
            .catch(err => {
              setUrl(url)
              saveError(err, 'saveImage > getDownloadURL')
            })
        })
        .catch(error => console.error(saveError(error, 'saveImage > getDownloadURL')))
    })
}

export const deleteImage = docRef => {

}


export const getProductsCategory = () => {
  return new Promise((resolve, reject) => {
    firebase.app().firestore().collection('productsCategory')
      .where('active', '==', true)
      .get()
      .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
      .catch(error => reject(saveError(error, 'getProductsCategory')))
  })
}

