import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import {
    saveDocument,
    updateDocument,
    deleteDocument,
    getCollectionFiltered
} from './abm'
import { snapshotToArray } from '../../utils'

const collection = 'productsGroup'

export const getGroupsRealTime = (setState = () => { }) =>
    firebase.app().firestore().collection(collection)
        .orderBy("sort")
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot.docs))
        })

export const saveGroup = supply => saveDocument(supply, collection)

export const updateGroup = supply => updateDocument(supply, collection)

export const deleteGroup = id => deleteDocument(id, collection)

export const getActiveGroups = () => getCollectionFiltered({ filters: [{ field: 'active', operation: '==', value: true }], collection, orderBy: 'name', orderDirection: 'asc' })

export const saveGroupImage = (file, setUrl = () => { }, updateProgress = () => { }) => {
    const imgName = parseInt(new Date().valueOf() * Math.random())
    const refStorage = firebase.storage().ref(`${collection}/${imgName}`)
    const refStorageResized = firebase.storage().ref(`${collection}/resized/${imgName}_300x300`)
    const task = refStorage.put(file)
    task.on('state_changed',
        snapshot => {
            const porcentaje = snapshot.bytesTransferred / snapshot.totalBytes * 100
            updateProgress(porcentaje)
        },
        err => {
            console.error(err, 'saveImage')
        },
        () => {
            task.snapshot.ref.getDownloadURL()
                .then(url => {
                    //setUrl(url)
                    updateProgress(99)
                    keepTrying(10, refStorageResized)
                        .then((url2) => {
                            setUrl(url2)
                            updateProgress(100)
                        })
                        .catch(err => {
                            setUrl(url)
                            updateProgress(100)
                            console.error(err, 'saveImage > getDownloadURL2')
                        })
                })
                .catch(error => console.error(error, 'saveImage > getDownloadURL'))
        })
}

function delay(t, v) {
    return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t)
    })
}

function keepTrying(triesRemaining, storageRef) {
    if (triesRemaining < 0) {
        return Promise.reject('out of tries');
    }

    return storageRef.getDownloadURL().then((url) => {
        return url;
    }).catch((error) => {
        switch (error.code) {
            case 'storage/object-not-found':
                return delay(2000).then(() => {
                    return keepTrying(triesRemaining - 1, storageRef)
                })
            default:
                console.log(error);
                return Promise.reject(error);
        }
    })
}


