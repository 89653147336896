//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { saveError } from './error'

export const getConfig = () => {
   return new Promise((resolve, reject) => {
      firebase.app().firestore().collection('config')
         .doc('config')
         .get()
         .then(conf => resolve(conf.data()))
         .catch(error => reject(saveError(error, 'getConfig')))
   })
}

export const getConfigRealTime = (setState = () => { }) =>
   firebase.firestore().collection('config').doc('config').onSnapshot((snapshot) => {
      // Obtener el nuevo documento
      const config = snapshot.data();

      // Actualizar el estado del usuario
      setState(config);
   })


export const updateConfig = config => new Promise((resolve, reject) => {
   firebase.app().firestore().collection('config').doc('config').update({
      ...config
   })
      .then(resolve('success'))
      .catch(error => reject(saveError(error, 'updateConfig')))
})