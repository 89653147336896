import React, { useEffect, useState } from 'react'
import {
    Grid,
    CircularProgress,
} from '@material-ui/core'
import GroupCarousel from './group'



export default function ProductListGrouped({ productsGrouped = [] }) {
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        // console.log('productsGrouped', productsGrouped)
        if (productsGrouped?.length === 0) {
            setLoading(true)
        }
    }, [productsGrouped])



    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ minHeight: '70vh' }}
            spacing={3}
        >
            {loading &&
                <Grid item>
                    <CircularProgress style={{ color: '#EC441B' }} />
                </Grid>
            }
            {
                productsGrouped?.map((group, i) => (
                    <Grid item xs={12} key={`${group.id}-${i}`}>
                        <GroupCarousel group={group} />
                    </Grid>
                ))
            }
        </Grid>
    )
}