import React, { useEffect, useState } from 'react'
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import { useStyles } from './styles'

export const ProductVariant = ({ product, list, setList }) => {
    const classes = useStyles()
    const [variants, setVariants] = useState({})

    useEffect(() => {
        // console.log('ProductVariant', product)
        return setVariants(loadVariants(product))
    }, [product, list])

    function loadVariants(product, subVariant) {
        //console.log('function loadVariants', product)
        const result = {}
        const { composition = [] } = product

        composition.filter(item => item.variant)
            .forEach(item => {
                const variantGroup = item.variantGroup || 'Variedad'
                //console.log('item filtered', item)

                if (result[variantGroup]) {
                    result[variantGroup] = [...result[variantGroup], item]
                } else {
                    result[variantGroup] = [item]
                }
            })

        if (!subVariant) {
            //ahora busco productos que no son variantes pero que si tienen variedad en su composicion
            //aplica para cuando es un combo con sub productos
            composition
                .filter(item => !item.variant)
                .forEach(item => {
                    const hasVariant = item.composition?.some(el => el.variant)
                    const { optional, checked } = item
                    //console.log('item', item)
                    // if (optional && !checked) {
                    //     return
                    // }
                    if (hasVariant) {
                        const variantGroup = item.name
                        if (result[variantGroup]) {
                            result[variantGroup] = [...result[variantGroup], item]
                        } else {
                            result[variantGroup] = [item]
                        }
                        //veo si en las variantes del producto ya esta seleccionada la variante actual
                        const existSome = product.variants?.some(el => el.variantId === item.id)
                        //console.log('existSome', existSome)
                        //seteo el elemento como variante seleccionada
                        if (!existSome) {
                            if ((optional && checked) || !optional) {
                                //console.log('procedo a setear la variante', variantGroup, checked, optional)
                                handleChangeVariant({ product, variantGroup, variantId: item.id })
                            }
                        } else {
                            // si existe, valido que este chequeado en caso de que sea opcional
                            if (optional && !checked) {
                                // quito la variante
                                //console.log('quito la variante', variantGroup)
                                handleChangeVariant({ product, variantGroup, variantId: item.id, deleteVariant: true })
                            }
                        }
                    }
                })
        }

        return result
    }

    function handleChangeVariant({ event, product, variantGroup = "Variedad", variantId = '', deleteVariant }) {
        //console.log('handleChangeVariant', { event, product, supplyId, variantGroup, variantId })
        if (!variantId) {
            variantId = event?.target?.value || ''
        }
        const { combo, variants = [] } = product

        const variant = product.composition.find(s => s.id === variantId)
        const variantDescription = variant?.name || variant?.description || ''
        const type = combo ? 'product' : 'supply'
        let exists = false
        let _variants = variants.map(v => {
            if (v.variantGroup === variantGroup) {
                exists = true
                return { variantId, variantDescription, variantGroup, type }
            }
            return v
        })
        if (!exists) {
            _variants = [...variants, { variantId, variantDescription, variantGroup, type }]
        }

        if (deleteVariant) {
            _variants = _variants.filter(el => el.variantId !== variantId)
        }

        const _prod = {
            ...product,
            variants: _variants
        }
        const items = list.map((item) => item._cod === _prod._cod ? _prod : item)

        //console.log('handleChangeVariant items', items)
        setList(items)


    }

    function handleChangeSubVariant({ event, productSelected, variantGroupChild, variantGroupSuper, subVariants }) {
        const subVariantId = event.target.value

        //console.log('handleChangeSubVariant', { variants, subVariantId, productSelected, variantGroupChild, variantGroupSuper, subVariants })
        //const variantDescription = productSelected.composition?.find(s => s.id === subVariantId)?.description || ''
        const type = 'supply'

        const { variants = [] } = productSelected

        let variant = {}
        subVariants[variantGroupChild].forEach(item => {
            if (item.id === subVariantId) {
                const variantDescription = item.description
                variant = { variantId: subVariantId, variantDescription, variantGroup: variantGroupChild, type }
                return
            }
        })

        let exists = false
        let _variants = variants.map(v => {
            if (v.variantGroup === variantGroupChild) {
                exists = true
                return variant
            }
            return v
        })
        if (!exists) {
            _variants = [...variants, variant]
        }

        const _subProd = {
            ...productSelected,
            variants: _variants
        }

        let { composition = [] } = product

        composition = composition.map(c => c.id === _subProd.id ? _subProd : c)

        const _prod = {
            ...product,
            composition
        }

        const items = list.map((item) => item._cod === _prod._cod ? _prod : item)

        setList(items)

    }

    function renderSubVariant(product, variantGroup) {
        const variant = product?.variants?.find(v => v.variantGroup === variantGroup)

        if (!variant)
            return (
                <></>
            )

        const productSelected = product.composition?.find(p => p.id === variant.variantId)
        const subVariants = loadVariants(productSelected, true)
        // console.log('renderSubVariant', productSelected)
        // console.log('renderSubVariant subVariants', subVariants)

        if (!subVariants)
            return (<></>)

        if (Object.values(subVariants).length === 0)
            return (<></>)

        const { variants = [] } = productSelected
        //console.log('productSelected variants', variants)

        return (
            Object.keys(subVariants).map((variantGroupChild, index) =>
                <Grid key={variantGroupChild} item xs={12} >
                    <FormControl className={classes.formControl}>
                        <InputLabel id="type">{`${variantGroupChild} de ${variant.variantDescription}`}</InputLabel>
                        <Select
                            labelId="type"
                            id="type"
                            value={variants.find(v => v.variantGroup === variantGroupChild)?.variantId || ''}
                            onChange={event => handleChangeSubVariant({ event, productSelected, variantGroupChild, variantGroupSuper: variantGroup, subVariants })}
                        >
                            {
                                subVariants[variantGroupChild].map(supply => <MenuItem key={supply.id} value={supply.id}>{supply.name || supply.description}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            )
        )
    }

    return (
        Object.keys(variants).map((variantGroup, index) =>
            <Grid key={variantGroup} item xs={12} >
                {
                    variants[variantGroup]?.length > 1 &&
                    <FormControl className={classes.formControl}>
                        <InputLabel id="type">{variantGroup}</InputLabel>
                        <Select
                            labelId="type"
                            id="type"
                            value={product.variants?.find(v => v.variantGroup === variantGroup)?.variantId || ''}
                            onChange={event => handleChangeVariant({ event, product, variantGroup })}
                        >
                            {
                                variants[variantGroup].map(supply => <MenuItem key={supply.id} value={supply.id}>{supply.name || supply.description}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                }

                {renderSubVariant(product, variantGroup)}
            </Grid>
        )
    )
}