import React, { useState } from 'react'
import {
    Grid,
    Typography,
    Card,
    ListItem,
    ListItemText,
    TextField,
    Button,
    IconButton,
    Tooltip,
    Paper
} from '@material-ui/core'
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import CancelIcon from '@material-ui/icons/Cancel';

const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']




export default function BusinessSchedule({ business, setBusiness }) {


    const handleAddHours = (day) => {
        const d = {
            hours: []
        }
        if (business.schedule[day]) {
            d.hours = [...business.schedule[day].hours]
        }
        d.hours.push({ start: '', end: '' })
        setBusiness({ ...business, schedule: { ...business.schedule, [day]: d } })

    }

    const handleDeleteHour = (day, index) => {
        const d = {
            hours: [...business.schedule[day].hours]
        }
        d.hours.splice(index, 1)
        setBusiness({ ...business, schedule: { ...business.schedule, [day]: d } })
    }

    return (
        <Grid
            container
            direction="row"
            justifycontent="flex-end"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Typography variant='h5'>Horarios</Typography>
            </Grid>
            <Paper>
                {
                    days.map((day, index) => (
                        <Grid item xs={12} key={day} container direction='row' alignItems='flex-start' style={{ padding: 10, backgroundColor: (index % 2) ? '#ffffff' : '#dedede' }}>
                            <Grid item xs={3} style={{ paddingTop: 7 }}>
                                <Typography variant='h6'>{day}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Agregar horario" aria-label="add">
                                    <IconButton color="primary" aria-label="add an alarm" onClick={() => handleAddHours(day)}>
                                        <AddAlarmIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                md={7}
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                {
                                    !business?.schedule[day]?.hours?.length && <Typography variant='body1' color="error" style={{ marginTop: 10 }}>Cerrado</Typography>
                                }
                                {
                                    business?.schedule[day]?.hours?.map((hour, index) => (
                                        <Grid
                                            item
                                            xs={12}
                                            key={index}
                                            container
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="center"
                                        >
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Desde"
                                                    type="time"
                                                    value={hour.start}
                                                    onChange={(e) => {
                                                        const d = {
                                                            hours: [...business.schedule[day]?.hours]
                                                        }
                                                        d.hours[index].start = e.target.value
                                                        setBusiness({ ...business, schedule: { ...business.schedule, [day]: d } })
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300, // 5 min
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} >
                                                <TextField
                                                    label="Hasta"
                                                    type="time"
                                                    value={hour.end}
                                                    onChange={(e) => {
                                                        const d = {
                                                            hours: [...business.schedule[day].hours]
                                                        }
                                                        d.hours[index].end = e.target.value
                                                        setBusiness({ ...business, schedule: { ...business.schedule, [day]: d } })
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300, // 5 min
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip title="Borrar horario">
                                                    <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteHour(day, index)} >
                                                        <CancelIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    ))
                                }

                            </Grid>
                        </Grid>
                    ))
                }
            </Paper>
        </Grid>
    )
}
