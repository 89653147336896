//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'

export default class User {
    constructor() {
        this.active = true
        this.name = ''
        this.email = ''
        this.admin = false
        this.delivery = false
        this.chef = false
        this.wallet = 0

        /* firebase.auth().onAuthStateChanged(user => {
            this.userLogged = user
        }) */
    }
}

export const getCurrentUser = () => firebase.auth().currentUser ? firebase.auth().currentUser : {}

export const getUsers = () => new Promise((resolve, reject) => {
    firebase.app().firestore().collection("users")
        .get()
        .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
        .catch(error => reject(saveError(error, 'getUsers')))
})

export const getUsersRealTime = (setState = () => { }) => {
    return firebase.app().firestore().collection('users')
        .orderBy('name', 'asc')
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot.docs))
        })
}

export const getDeliveriesRealTime = (setState = () => { }) => {
    return firebase.app().firestore().collection('users')
        .where('delivery', '==', true)
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot.docs))
        })
}

export const getDeliveries = () => new Promise((resolve, reject) => {
    firebase.app().firestore().collection("users")
        .where('delivery', '==', true)
        .get()
        .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
        .catch(error => reject(saveError(error, 'getDeliveries')))
})

export const getUser = (user) => new Promise((resolve, reject) =>
    firebase.app().firestore().collection("users").doc(user.email).get()
        .then(doc => {
            !doc.exists && resolve(false)
            resolve(doc.data())
        })
        .catch(error => reject(saveError(error, 'getUser')))
)

export const getUserRealTime = (user, setState = () => { }) =>
    firebase.app().firestore().collection("users").doc(user.email)
        .onSnapshot(doc => {
            !doc.exists && setState(null)
            setState(doc.data())
        })

export const isUserChef = user => new Promise((resolve, reject) => {
    if (!user) resolve(false)
    if (!user.email) resolve(false)

    let docRef = firebase.app().firestore().collection("users").doc(user.email)
    docRef.get()
        .then(doc => {
            !doc.exists && resolve(false)
            resolve(doc.data().chef)
        })
        .catch(error => reject(error))
})

export const isUserDelivery = user => new Promise((resolve, reject) => {
    if (!user) resolve(false)
    if (!user.email) resolve(false)

    let docRef = firebase.app().firestore().collection("users").doc(user.email)
    docRef.get()
        .then(doc => {
            !doc.exists && resolve(false)
            resolve(doc.data().delivery)
        })
        .catch(error => reject(error))
})


export const isUserAdmin = (user) => {
    return new Promise((resolve, reject) => {
        if (!user) resolve(false)
        if (!user.email) resolve(false)

        let docRef = firebase.app().firestore().collection("users").doc(user.email)
        docRef.get()
            .then(doc => {
                !doc.exists && resolve(false)
                resolve(doc.data().admin)
            })
            .catch(error => reject(error))
    })
}

export const isUserDev = (user) => {
    return new Promise((resolve, reject) => {
        if (!user) resolve(false)
        if (!user.email) resolve(false)

        let docRef = firebase.app().firestore().collection("users").doc(user.email)
        docRef.get()
            .then(doc => {
                !doc.exists && resolve(false)
                resolve(doc.data().dev)
            })
            .catch(error => reject(error))
    })
}


export const updateUser = (user) => new Promise((resolve, reject) =>
    firebase.app().firestore().collection('users').doc(user.email).update({
        ...user
    })
        .then(resolve({ message: 'Usuario modificado' }))
        .catch(error => reject(saveError(error, 'updateUser')))
)

export const saveUser = (user) => new Promise((resolve, reject) => {
    user.id ?
        firebase.app().firestore().collection('users').doc(user.id).update({
            ...user
        })
            .then(resolve({ message: 'Usuario modificado' }))
            .catch(error => reject(saveError(error, 'saveUser > update')))
        :
        firebase.app().firestore().collection('users')
            .doc(user.email)
            .set(user)
            .then(() => resolve({ message: 'Usuario ingresado' }))
            .catch(error => reject(saveError(error, 'saveUser > add')))
})

export const deleteUser = (staffId) => new Promise((resolve, reject) => {
    firebase.app().firestore().collection('users').doc(staffId).delete()
        .then(resolve('Usuario borrado'))
        .catch(error => reject(saveError(error, 'deleteUser')))
})