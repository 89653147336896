import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
//import Title from '../components/title'
import MyAccountWithData from '../containers/myAccountWithData'

const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    root: {
        flexGrow: 1,
    },
}));

export const MyAccountPage = ({ user, config }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.root} spacing={1} direction="column" justify="center" align="center">
            <MyAccountWithData user={user} config={config} />
        </Grid>
    )
}

export default MyAccountPage