import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
   Grid,
   Typography,
   List,
   ListItem,
   ListItemText,
} from '@material-ui/core'
import { spanishDaysArray } from '../../utils/dateFunctions'

const useStyles = makeStyles((theme) => ({
   schedule: {
      margin: theme.spacing(1),
   },
   description: {
      margin: theme.spacing(1),
   },
   ItemText: {
      width: 12
   },
   List: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   }
}))


export default function businessInfo({ businessInfo, setInfo = () => { }, updateInfo = () => { } }) {
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const classes = useStyles()
   return (
      <Grid container>
         <Grid item xs={12} className={classes.description}>
            <Typography>
               {businessInfo?.description}
            </Typography>
         </Grid>
         <Grid item xs={12} className={classes.schedule}>
            <Typography>
               Horarios
            </Typography>
            <List dense={true} className={classes.List}>
               {spanishDaysArray.map((day, i) => (
                  <ListItem key={day} button style={{ color: (!businessInfo?.schedule[day]?.hours?.length) ? 'red' : '', backgroundColor: (i % 2) ? '#ffffff' : '#f3f3f3' }} >
                     <Grid container justifyContent='center'>
                        <Grid item xs={4}>
                           <Typography style={{ fontWeight: 'bold' }}>{day}</Typography>
                        </Grid>
                        <Grid item xs={8} container spacing={1} justifycontent="center">
                           {
                              !businessInfo?.schedule[day]?.hours?.length && (
                                 <Grid item xs={12}>
                                    <Typography>Cerrado</Typography>
                                 </Grid>
                              )
                           }
                           {businessInfo?.schedule[day]?.hours?.map(hour => (
                              <React.Fragment key={hour.start}>
                                 <Grid item xs={6}>
                                    <ListItemText className={classes.ItemText} primary={`Desde ${hour.start}`} />
                                 </Grid>
                                 <Grid item xs={6}>
                                    <ListItemText className={classes.ItemText} primary={`Hasta ${hour.end}`} />
                                 </Grid>
                              </React.Fragment>
                           ))}
                        </Grid>
                     </Grid>
                  </ListItem>
               ))}
            </List>
         </Grid>
      </Grid >
   )
}
