//import firebase from 'firebase/app'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { saveError } from './obj/error'
import { navigate } from '@reach/router'


export const loginWithGoogle = (callback = () => { }) => new Promise(async (res, rej) => {
    try {

        // firebase.auth().getRedirectResult().then(function (result) {
        //     if (result.credential) {
        //         // This gives you a Google Access Token.
        //         var token = result.credential.accessToken;
        //     }
        //     if (typeof callback === "function") {
        //         callback()
        //     }

        //     var user = result.user;
        //     res(user)
        // });

        // // Start a sign in process for an unauthenticated user.
        // const provider = new firebase.auth.GoogleAuthProvider();
        // provider.addScope('profile');
        // provider.addScope('email');
        // firebase.auth().signInWithRedirect(provider);

        const provider = new firebase.auth.GoogleAuthProvider()
        firebase.auth().signInWithPopup(provider)
            .then(result => {
                if (typeof callback === "function") {
                    callback()
                }

                res(result.user)
            })
            .catch(error => rej(error))

    } catch (error) {
        rej(error)
    }

})

export const loginWithFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider()
    firebase.auth().signInWithPopup(provider)
        .then(result => {
        })
        .catch(error => console.error(saveError(error, 'userContainer loginWithFacebook')))
}

export const logOut = (e) =>
    firebase.auth().signOut()
        .then(() => {
            navigate("/")
            window.location.reload()
        })
        .catch(error => console.error(saveError(error, 'userContainer logOut')))