import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    TextField,
    Button,
    Snackbar,
    Avatar,
    Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '100%',
    },
    input: {
        width: '98%',
    },
    input100: {
        width: '100%',
    },
    selectRegion: {
        width: '100%'
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}))


export default function ClientForm({ logOut = () => { }, selectedClient = {}, addresses = {} }) {
    //console.log('ClientForm called')
    const classes = useStyles()
    const [client, setClient] = useState(selectedClient)

    client.id !== selectedClient.id && setClient(selectedClient)

    return (
        <Grid
            direction="column"
            justify="center"
            alignItems="center"
            spacing={6}>

            <Grid item xs={12} spacing={6} style={{ paddingBottom: 15 }}>
                <Avatar className={classes.avatar} alt={client.name} src={client.photoURL} />
            </Grid>
            <Grid item xs={12} spacing={6}>
                <Typography variant="h4" component="h1" >{client.name}</Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3} style={{ paddingTop: 8 }}>
                <Typography variant="h6" component="h2" color="textSecondary" >{client.email}</Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3} style={{ padding: 20 }}>
                <Typography variant="h6" component="h3" >{client.points || 0} puntos</Typography>
            </Grid>
            <Grid item xs={6} md={2} lg={2} style={{ paddingTop: 50 }}>
                <Button style={{ width: '100%' }} variant="outlined" color="secondary" onClick={logOut} >Cerrar sesión</Button>
            </Grid>
        </Grid>
    )

}