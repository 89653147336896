import React, { useEffect, useState } from 'react'
import {
    Grid,
    TextField,
    Button,
    Typography,
    Checkbox,
    FormControlLabel
} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { useStyles } from './styles'
import Dialog from '../../dialog'
import ClientAddress from '../../../firebase/obj/clientAddress'
import { usePosition } from '../../../hooks/usePosition'
import DialogMap from '../../dialogMap'

const voidF = () => { }

export const AddressForm = ({ selected, openForm, setOpenForm = voidF, saveAddress = voidF, config = {} }) => {
    const classes = useStyles()
    const [error, setError] = useState('')
    const [address, setAddress] = useState({})
    const [dialogMapOpen, setDialogMapOpen] = useState(false)
    const position = usePosition() // {latitude, longitude, error}

    useEffect(() => {
        if (!openForm) {
            return setAddress(new ClientAddress())
        }
        return setAddress(selected || new ClientAddress())
    }, [openForm])




    const handleSave = () => {

        address.createdAt = new Date()

        const onError = error => {
            console.error('Error', error)
            setError(error)
        }

        if (validate())
            saveAddress({ ...address }, onError)
    }

    const validate = () => {
        let _error = null

        if (!address.address)
            _error = 'Ingrese dirección'

        // if (!address.number)
        //     _error = 'Ingrese número de puerta'

        // if (!address.phone)
        //     _error = 'Ingrese número de teléfono'

        if (address.position?.length === 0)
            _error = 'Seleccione la ubicación'


        setError(_error)
        return _error ? false : true
    }

    const setClientPosition = latLng => {
        const position = [latLng.lat, latLng.lng]
        setAddress({ ...address, position })
    }



    return (
        <>
            <DialogMap
                oneButton={true}
                open={dialogMapOpen}
                setState={setDialogMapOpen}
                setPosition={setClientPosition}
                markerPosition={address.position || [position.latitude, position.longitude] || []}
                client={address}
                title={`${address.address || 'Ubicación de domicilio'} ${address.number || ''}`}
                config={config}
            />
            <Dialog disableBackdropClick={true} message={'Crear dirección'} fullWidth={true} maxWidth={'sm'} open={openForm} setOpen={setOpenForm} acceptText={null} cancelText={null} >
                <Grid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={12} style={{ width: '100%' }}>
                        <TextField type="text" name="address" id="address" variant="outlined" value={address.address || ''} onChange={e => setAddress({ ...address, address: e.target.value })} label="Dirección" size="small" className={classes.inputTxt} required />
                    </Grid>
                    {/* <Grid item xs={4} md={4} lg={3} style={{ width: '100%' }} >
                        <TextField rows="3" autoComplete="off" name="number" id="number" variant="outlined" value={address.number || ''} onChange={e => setAddress({ ...address, number: e.target.value })} label="Nro. puerta" size="small" className={classes.inputTxt} required />
                    </Grid> */}
                    {/* <Grid item xs={12} style={{ width: '100%' }} >
                        <TextField rows="3" autoComplete="off" name="phone" id="phone" variant="outlined" value={address.phone || ''} onChange={e => setAddress({ ...address, phone: e.target.value })} label="Teléfono" size="small" className={classes.inputTxt} required />
                    </Grid> */}
                    <Grid item xs={12} style={{ width: '100%' }} >
                        <TextField rows="3" autoComplete="off" name="description" id="description" variant="outlined" value={address.description || ''} onChange={e => setAddress({ ...address, description: e.target.value })} label="Descripción" placeholder="Aclaraciones adicionales" size="small" className={classes.inputTxt} />
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%', padding: 8 }} >
                        <Button startIcon={<LocationOnIcon />} variant="outlined" color={address.position?.length > 0 ? 'primary' : 'secondary'} onClick={() => { setDialogMapOpen(!dialogMapOpen) }}>{address.position?.length > 0 ? 'Ubicación seleccionada' : 'Seleccione ubicación'}</Button>
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%', padding: 8 }} >
                        <FormControlLabel
                            label="Marcar como dirección principal"
                            control={
                                <Checkbox color="primary" checked={address.principal} onChange={e => setAddress({ ...address, principal: e.target.checked })} />

                            }
                        />

                    </Grid>
                    <Grid item xs={12} style={{ padding: 3, width: '100%' }} >
                        {
                            error && <Typography className={classes.inputTxt} color="secondary">{error}</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 3, width: '100%' }} >
                        <Button className={classes.inputTxt} variant="contained" color="primary" onClick={handleSave}>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>

    )
}

export default AddressForm