
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    listContainer: {
        width: '100%',
        paddingTop: 50,
        paddingBottom: 50,
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: '75vh'
    },
    listItem: {
        paddingTop: 20,
        paddingBottom: 20
    },
    secondary: {
        color: '#757575'
    },
    input: {
        width: '100%'
    },
    confirmButtonContainer: {
        paddingTop: 50,
        paddingBottom: 50,
    },
    link: {
        textDecoration: 'none',
        color: '#000'
    },
}))