import React, { useCallback } from 'react'
import ReactDOM from 'react-dom'
import {
    Snackbar
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { Link } from '@reach/router'

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        color: '#000'
    }
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const notificationContainer = document.getElementById('notifications')

export const Notification = ({ notificationOpen = false, autoHideDuration = 2000, handleNotificationClose = () => { }, severity = 'success', notificationMessage = '', link = '', closeButton = true, children }) => {
    const classes = useStyles()

    const handleClose = useCallback(
        (evt, reason) => {
            if (reason === "clickaway") return
            handleNotificationClose(false)
        },
        [handleNotificationClose]
    )


    return ReactDOM.createPortal(
        <Snackbar styles={{ zIndex: 2000 }} open={notificationOpen} autoHideDuration={autoHideDuration} onClose={handleClose}>
            {
                link ?
                    <Link to={link} className={classes.link}>
                        <Alert onClose={handleClose} severity={severity}>
                            {notificationMessage}
                        </Alert>
                    </Link>
                    :
                    (
                        closeButton ?
                            <Alert onClose={handleClose} severity={severity}>
                                {children || notificationMessage}
                            </Alert>
                            :
                            <Alert severity={severity}>
                                {children || notificationMessage}
                            </Alert>
                    )

            }
        </Snackbar>,
        notificationContainer
    )

}




export default Notification