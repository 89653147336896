

export const findAddress = (fullAddress = '', addresses = []) => {
    const _address = addresses?.find(a => {
        const ADDRESS = (a.address?.toUpperCase() || '').trim()
        const NUMBER = (a.number?.toUpperCase() || '').trim()
        if (fullAddress.toUpperCase() === `${ADDRESS} ${NUMBER}`) {
            return a
        }
        if (ADDRESS.includes(fullAddress.toUpperCase())) {
            return a
        }
        return null
    })
    // console.log('findAddress _address', _address)
    return _address
}

export const fullAddressDescriptionFromAdrres = (data = {}) => {
    try {
        const { address = '', description = '', number } = data
        if (address && number)
            return `${address} ${number}`

        // if (address && description)
        //     return `${address} ${description}`

        if (address)
            return address

        if (description)
            return description
    } catch (error) {
        console.error('fullAddressDescriptionFromAdrres', error)
    }
    return ''
}

export const normalizeOrderAddress = (address) => {
    try {
        if (typeof address === 'string' || address instanceof String) {
            return address
        }
    } catch (error) {
        console.log('normalizeOrderAddress', error)
    }
    return '{Error}'
}