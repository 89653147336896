import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Title from '../components/title'
import BusinessInfoWithData from '../containers/businessInfoWithData'

const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    root: {
        flexGrow: 1,
    },
}))

export const BusinessInfoPagePublic = () => {
    const classes = useStyles()

    return (
        <Fragment>
            <Title>Información de empresa</Title>
            <Grid container className={classes.root} spacing={1}>
                <BusinessInfoWithData publicData={true} />
            </Grid>
        </Fragment>
    )
}

export default BusinessInfoPagePublic