import React, { createContext } from 'react'
import useMainContext from '../hooks/useMainContext'
import { getDeliveryReport } from '../firebase/obj/report'

export const ReportContext = createContext()

const Provider = ({ children }) => {
    const { isAdmin } = useMainContext()



    const fetchDeliveryData = async (reportFilters) => {
        console.log('fetchDeliveryData', reportFilters)
        try {
            if (!isAdmin) {
                return {
                    error: true,
                    message: 'No tienes permisos para realizar esta acción'
                }
            }
            const { startDate, endDate, deliveryId } = reportFilters
            const data = await getDeliveryReport(startDate, endDate, deliveryId)
            return data

        } catch (error) {
            console.error('fetchDeliveryData', error)
            return {
                error: true,
                message: 'Error al obtener los datos de reparto'
            }
        }
    }


    const value = {
        fetchDeliveryData
    }

    return (
        <ReportContext.Provider value={value}>
            {children}
        </ReportContext.Provider>
    )
}

const context = {
    Provider,
    Consumer: ReportContext.Consumer
}

export default context