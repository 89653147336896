import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    receiptContainer: {
        position: 'relative',
        boxSizing: 'border-box',
        minWidth: '300px',
        minHeight: '450px',
        margin: 'auto',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
    }
}))