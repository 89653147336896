import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import {
    getDocument,
} from './abm'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'

const collection = 'paymentMethods'

export const getPaymentMethods = () => new Promise((resolve, reject) => {
    firebase.app().firestore().collection(collection)
        .where('active', '==', true)
        .get()
        .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
        .catch(error => reject(saveError(error, 'getPaymentMethods')))
})

export const getAllPaymentMethods = () => new Promise((resolve, reject) => {
    firebase.app().firestore().collection(collection)
        .get()
        .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
        .catch(error => reject(saveError(error, 'getPaymentMethods')))
})

export const getPaymentMethod = id => getDocument(id, collection)
