//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { saveError } from './error'

export const getBusinessInfo = () => {
   return new Promise((resolve, reject) => {
      firebase.app().firestore().collection('businessInfo')
         .doc('businessInfo')
         .get()
         .then(businessInfo => resolve(businessInfo.data()))
         .catch(error => reject(saveError(error, 'getBusinessInfo')))
   })
}

export const updateBusinessInfo = businessInfo => new Promise((resolve, reject) => {
   firebase.app().firestore().collection('businessInfo').doc('businessInfo').update({
      ...businessInfo
   })
      .then(resolve('success'))
      .catch(error => reject(saveError(error, 'updateBusinessInfo')))
})