import React from 'react'
import { Marker } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'



const iconD = new L.Icon({
    iconUrl: require('../../img/delivery.png'),
    //iconRetinaUrl: require('../../img/delivery.png'),
    /* iconSize: [64, 64],
    iconAnchor: [32, 64], 
    popupAnchor: [-3, -76],*/
    iconSize: [30, 41],
    iconAnchor: [15, 41],
    popupAnchor: [-2, -28],
})

const iconRed = new L.Icon({
    iconUrl: require('../../img/markerRed.png'),
    iconSize: [30, 41],
    iconAnchor: [15, 41],
    popupAnchor: [-2, -28],
})

const iconBurger = new L.Icon({
    iconUrl: require('../../img/beefburger.png'),
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [-2, -28],
})

const iconScooter = new L.Icon({
    iconUrl: require('../../img/delivery-bike.png'),
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [-2, -28],
})


export const DeliveryMarker = (props) =>
    <Marker styles={{ zIndex: 100 }} icon={iconScooter} {...props} >
        {props.children}
    </Marker>

