// componente SearchInput que renderiza un input de búsqueda y si el tamaño de la pantalla es menor a 600px, renderiza un icono de búsqueda que al hacer click, muestra el input de búsqueda en un popup.

// Path: src/components/searchInput/index.js

import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, InputBase, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import useMainContext from '../../hooks/useMainContext'
// import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#f5f5f5ff',
        height: 35
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,

    },
    iconButton: {
        padding: 10,
    },
}))

export const SearchInput = ({ show }) => {
    const classes = useStyles()
    const location = useLocation();
    const { search, setSearch, isAdmin, isChef, isDelivery } = useMainContext()
    const [visible, setVisible] = useState(false)
    // const { t } = useTranslation()

    useEffect(() => {
        if (show || location.pathname === "/productos" || (location.pathname === "/" && !isAdmin && !isChef && !isDelivery)) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [location])

    if (!visible) {
        return null
    }

    return (
        <Paper className={classes.root}>
            <InputBase
                className={classes.input}
                placeholder={'Buscar...'}
                inputProps={{ 'aria-label': 'search' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            {
                search ?
                    <IconButton
                        type="button"
                        className={classes.iconButton}
                        aria-label="clear"
                        onClick={() => setSearch('')}
                        color="secondary"
                    >
                        <CloseIcon />
                    </IconButton>
                    :
                    <IconButton
                        type="button"
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={() => { }}
                    >
                        <SearchIcon />
                    </IconButton>
            }

        </Paper>
    )
}

export default SearchInput
