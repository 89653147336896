import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'


export const getOrdersReportDaily = (startDate, endDate) =>
    new Promise((resolve, reject) => {
        firebase.app().firestore().collection('ordersReportDaily')
            .where("date", ">=", startDate)
            .where("date", "<=", endDate)
            .orderBy('date', 'asc')
            .get()
            .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs).filter(r => r.orderCount > 0)))
            .catch(reject)
    })

export const getOrdersReportMonthly = (startDate, endDate) =>
    new Promise((resolve, reject) => {
        firebase.app().firestore().collection('ordersReportMonthly')
            .where("date", ">=", startDate)
            .where("date", "<=", endDate)
            .orderBy('date', 'asc')
            .get()
            .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs).filter(r => r.orderCount > 0)))
            .catch(reject)
    })

export const getOrdersReportYearly = (startDate, endDate) =>
    new Promise((resolve, reject) => {
        firebase.app().firestore().collection('ordersReportYearly')
            .where("date", ">=", startDate)
            .where("date", "<=", endDate)
            .orderBy('date', 'asc')
            .get()
            .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs).filter(r => r.orderCount > 0)))
            .catch(reject)
    })

export const getDeliveryReport = (startDate, endDate, deliveryId) =>
    new Promise((resolve, reject) => {
        firebase.app().firestore().collection('users').doc(deliveryId).collection('orders')
            .where("date", ">=", startDate)
            .where("date", "<=", endDate)
            .orderBy('date', 'asc')
            .get()
            .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
            .catch(reject)
    })

