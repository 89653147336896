
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
})

export default function AlertDialog({ style, disableBackdropClick, fullWidth, maxWidth = 'sm', acceptText = 'Si', cancelText = 'No', children, obj = {}, message = '', open = false, setOpen = () => { }, acceptChanges = () => { }, onClose = () => { } }) {

  const handleClose = () => {
    setOpen(false)
    onClose(false)
  }

  const handleYes = () => {
    setOpen(false)
    acceptChanges(obj)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
      style={style || {}}
    >
      <DialogTitle id="alert-dialog-title" style={{ marginRight: 40 }} onClose={handleClose}>{message}</DialogTitle>
      {
        children &&
        <DialogContent>
          {children}
        </DialogContent>
      }
      <DialogActions>
        {
          cancelText &&
          <Button onClick={handleClose} color="primary">
            {cancelText}
          </Button>
        }
        {
          acceptText &&
          <Button onClick={handleYes} color="primary" autoFocus>
            {acceptText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}
