import React from 'react'
import {
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup,
} from '@material-ui/core'

export default function PickupOptions({ visible, pickup = false, setPickup, disabled }) {

    if (!visible)
        return <></>

    return (
        <FormControl component="fieldset" disabled={disabled}>
            <FormGroup>
                <FormControlLabel
                    label="Retiro en local"
                    control={
                        <Checkbox
                            checked={pickup}
                            onChange={() => setPickup(!pickup)}
                            name="pickup"
                            color="primary"
                            disabled={disabled}
                        />
                    }
                />
            </FormGroup>
        </FormControl>

    )
}
