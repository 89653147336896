import React, { useState, useEffect } from 'react'
import { getOrderByIdRealTime } from '../firebase/obj/order'
import OrderView from '../components/order/orderView'
import { registerClientTopic } from '../firebase/obj/notificationTopic'

export const OrderWithData = ({ user, id, config }) => {
    const [order, setOrder] = useState({})

    useEffect(() => {
        if (id) {
            if (user)
                handleNotification()

            const unsubscribe = getOrderByIdRealTime(id, setOrder)
            return () => unsubscribe()
        }
    }, [id, user])

    const handleNotification = () => {
        try {
            const topic = {
                id: user.uid,
                token: '',
                topic: user.uid,
                userEmail: user.email,
                userId: user.uid,
                user: user.displayName || '',
                granted: new Date()
            }

            if (!("Notification" in window)) {
                // Check if the browser supports notifications
                alert("Este navegador no soporta notificaciones de escritorio");
            } else if (Notification.permission === "granted") {
                // Check whether notification permissions have already been granted;
                // if so, create a notification
                //const notification = new Notification("Hi there!")
                registerClientTopic(topic)
            } else if (Notification.permission !== "denied") {
                // We need to ask the user for permission
                Notification.requestPermission().then((permission) => {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        registerClientTopic(topic)
                    }
                })
            }
        } catch (error) {
            console.log('handleNotification', error)
        }
    }


    return (
        <OrderView order={order} config={config} />
    )
}

export default OrderWithData