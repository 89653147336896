import React from 'react'
import {
    Typography
} from '@material-ui/core'
import { smartNotesToList } from '../../../utils/productEdit'

export default function SmartNotes({ smartNotes = '', className, typographyColor = 'inherit' }) {
    return (
        <div className={className}>
            {
                smartNotesToList(smartNotes).map((note, index) =>
                    <Typography key={index} color={typographyColor} >{note}</Typography>
                )
            }
        </div>
    )
}
