import React, { Fragment } from 'react'
import ListOfProductsWithData from '../containers/listOfProductsWithData'
import MyOrdersWithData from '../containers/myOrdersWithData'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Context from '../context/mainContext'

const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    root: {
        flexGrow: 1,
    },
}))

export const HomePage = () => {
    const classes = useStyles()

    return (
        <Fragment>
            <Grid container item className={classes.root} spacing={1} xs={12} alignItems="center" justify="center">
                <Context.Consumer>
                    {
                        ({ user, config }) =>
                            <>
                                <Grid container item xs={12} alignItems="center" justify="center">
                                    <MyOrdersWithData user={user} config={config} />
                                </Grid>
                                <Grid container item xs={12} alignItems="center" justify="center">
                                    <ListOfProductsWithData publicData={true} />
                                </Grid>
                            </>
                    }
                </Context.Consumer>
            </Grid>
        </Fragment>
    )
}

export default HomePage