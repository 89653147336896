import React from 'react'
import Title from '../components/title'
import CartWithData from '../containers/cartWithData'
import { Context } from '../context/mainContext'

export const CartPage = () => {

    return (
        <>
            <Title>Completa tu compra!</Title>
            <Context.Consumer>
                {
                    ({ cart, setCart, user, config }) =>
                        <CartWithData cart={cart} setCart={setCart} user={user} config={config} />
                }
            </Context.Consumer>

        </>
    )
}

export default CartPage