/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    Typography,
    Snackbar,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import BusinessSchedule from './Schedule'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    input: {
        margin: 10,
        width: 260
    },
    inputTextArea: {
        width: '100%',
        marginBottom: 15
    },
    buttonSave: {
        margin: 10,
        width: 260
    },
    inputHours: {
        margin: 10,
        width: 200
    },
    ItemText: {
        width: 10
    },
    List: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function businessInfoForm({ businessInfo = {}, setInfo = () => { }, updateInfo = () => { } }) {
    const classes = useStyles()
    const [notificationOpen, setNotoficationOpen] = React.useState(false)
    const [notificationMessage, setNotificationMessage] = React.useState('Datos guardados')
    // const [day, setDay] = React.useState('Lunes')
    // const [hourFrom, setHourFrom] = React.useState('19:00')
    // const [hourTo, setHourTo] = React.useState('02:00')
    // const [days, setDays] = React.useState(businessInfo?.schedule ? businessInfo?.schedule :
    //     [
    //         { day: 'Lunes', from: '19:00', to: '02:00' },
    //         { day: 'Martes', from: '19:00', to: '02:00' },
    //         { day: 'Miércoles', from: '19:00', to: '02:00' },
    //         { day: 'Jueves', from: '19:00', to: '02:00' },
    //         { day: 'Viernes', from: '19:00', to: '02:00' },
    //         { day: 'Sábado', from: '19:00', to: '02:00' },
    //         { day: 'Domingo', from: '19:00', to: '02:00' }
    //     ]
    // )


    const saveBusinessInfo = (event) => {
        updateInfo().then(res => {
            setNotoficationOpen(true)
            setNotificationMessage('Información de empresa guardada')
        })
    }

    const handleBusinessInfo = event => {
        let info = {
            ...businessInfo,
            [event.target.id]: event.target.value
        }
        setInfo(info)
    }

    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setNotoficationOpen(false)
    }


    // const handleApply = e => {
    //     let _days = days.map(d => {
    //         if (day === d.day) {
    //             d.from = hourFrom
    //             d.to = hourTo
    //         }
    //         return d
    //     })
    //     setDays(_days)
    //     setInfo({ ...businessInfo, schedule: _days })
    // }

    const handlePositionChange = (event) => {
        const { id, value } = event?.target || {}
        let position = [
            Number(businessInfo?.position?.at(0) || 0),
            Number(businessInfo?.position?.at(1) || 0),
        ]
        if (id === 'latitude') {
            position[0] = value
        }
        if (id === 'longitude') {
            position[1] = value
        }

        setInfo({ ...businessInfo, position })
    }

    return (
        <>
            <Snackbar open={notificationOpen} autoHideDuration={1500} onClose={handleNotificationClose}>
                <Alert onClose={handleNotificationClose} severity="success">
                    {notificationMessage}
                </Alert>
            </Snackbar>
            <Grid
                container
                spacing={2}
                direction="row"
                justify="space-around"
                alignItems="flex-start"
                style={{ padding: 20 }}
            >
                <Grid item xs={12} md={6} lg={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ marginBottom: 10 }}>
                            <Typography variant='h5'>Información general</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField className={classes.inputTextArea} variant="outlined" multiline={true} onChange={e => handleBusinessInfo(e)} id="description" value={businessInfo?.description} label="Descripción de la empresa" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField className={classes.inputTextArea} variant="outlined" onChange={e => handleBusinessInfo(e)} id="whatsapp" value={businessInfo?.whatsapp} label="Whatsapp" placeholder="+598..." />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField className={classes.inputTextArea} type="number" variant="outlined" onChange={e => handleBusinessInfo(e)} id="phone" value={businessInfo?.phone} label="Teléfono" />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField className={classes.inputTextArea} variant="outlined" onChange={e => handleBusinessInfo(e)} id="address" value={businessInfo?.address} label="Dirección" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField className={classes.inputTextArea} variant="outlined" onChange={e => handlePositionChange(e)} id="latitude" value={businessInfo?.position?.at(0)} label="Latitud" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField className={classes.inputTextArea} variant="outlined" onChange={e => handlePositionChange(e)} id="longitude" value={businessInfo?.position?.at(1)} label="Longitud" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                >
                    <BusinessSchedule business={businessInfo} setBusiness={setInfo} />
                    {/* <Grid container justify='flex-end'>
                            <Grid item xs={12} sm={7} lg={5} >
                                <InputLabel id="selectDayLabel"></InputLabel>
                                <Select
                                    labelId="selectDayLabel"
                                    id="selectDay"
                                    value={day}
                                    onChange={e => setDay(e.target.value)}
                                >
                                    {
                                        days.map(d => <MenuItem key={d.day} value={d.day} >{d.day} </MenuItem>)
                                    }
                                </Select>
                                {' '}
                                <TextField
                                    id="time"
                                    label="Desde"
                                    type="time"
                                    defaultValue="19:00"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => setHourFrom(e.target.value)}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                                {' '}
                                <TextField
                                    id="time"
                                    label="Hasta"
                                    type="time"
                                    defaultValue="02:00"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => setHourTo(e.target.value)}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                                <Grid item xs={12}>
                                    <Button onClick={handleApply} className={classes.inputHours} variant="contained" color="primary">Aplicar</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={6}>
                                <List dense={true} className={classes.List}>
                                    {days.map(d =>
                                        <ListItem key={d.day} button style={d.from === d.to ? { color: 'red' } : {}}>
                                            <ListItemText className={classes.ItemText}
                                                primary={d.day}
                                            />
                                            <ListItemText className={classes.ItemText}
                                                primary={`Desde: ${d.from}`}
                                            />
                                            <ListItemText className={classes.ItemText}
                                                primary={`Hasta: ${d.to}`}
                                            />
                                        </ListItem>
                                    )
                                    }
                                </List>
                            </Grid>
                        </Grid> */}
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Button variant="contained" color="primary" onClick={saveBusinessInfo} fullWidth style={{ margin: 20, height: 50 }}>
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </ >
    )
}
