import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Card,
  CardActions,
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  Grid,

} from '@material-ui/core'
import InputNumber from '../../inputNumber'
import OrderProductEdit from '../../order/orderProductEdit'
import { generateSmartComposition } from '../../../utils/productEdit'


const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 345,
    zIndex: 10,
    margin: 10,
    transition: 'transform .2s',
  },
  rootWithZoom: {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  rootSM: {
    maxWidth: 345,
    margin: '0 10px 20px 10px',
    transition: 'transform .2s',
  },
  rootSMWithZoom: {
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  media: {
    minHeight: 330,
    maxHeight: 345,
  },
  price: {
    fontSize: 20
  },
  previousPrice: {
    fontSize: 20,
    marginRight: 5,
    textDecoration: 'line-through'
  },
  cardActions: {
    marginTop: 'auto'
  }
})

const ProductInfo = ({ sale, available }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {sale &&
          <Typography style={{ background: '#70B67B', color: 'white', marginTop: -48 }} align='center'  >
            Oferta!
          </Typography>
        }
      </Grid>
      <Grid item xs={12}>
        {!available &&
          <Typography style={{ background: '#FF0000', color: 'white', marginTop: -24 }} align='center'  >
            Agotado
          </Typography>
        }
      </Grid>
    </Grid>
  )
}


export default function ProductCard({ product = {}, cart = {}, addToCart = () => { }, setCart = () => { }, zoomOnHover = true }) {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  // const [dialog, setDialog] = useState({})
  const [editDisabled, setEditDisabled] = useState(true)
  const [editLineOpen, setEditLineOpen] = useState(false)
  const [localProduct, setLocalProduct] = useState({})

  useEffect(() => {
    const { productsList = [] } = cart
    const qty = getQty()
    const _product = productsList?.find(prod => prod.id === product.id) || { ...product }
    const smartComposition = _product.smartComposition || generateSmartComposition(_product).smartComposition
    setLocalProduct({ ..._product, smartComposition, qty })
  }, [product, cart])

  //const setDialogOpen = open => setDialog({ ...dialog, open })

  // const handleAddProduct = () => setDialog({
  //   open: true,
  //   setOpen: setDialogOpen,
  //   message: product.name || 'Añadir al carrito'
  // })

  const handleChangeQty = ({ qty = 0 }) => {
    if (qty < 1)
      qty = 0

    const item = {
      ...product,
      qty
    }

    if (typeof addToCart === 'function')
      addToCart(item)

    if (localProduct.id === item.id)
      setLocalProduct(item)
  }

  const getQty = () => {
    const prod = cart?.productsList?.find(p => p.id === product.id)
    const { qty = 0 } = prod || {}
    setEditDisabled(qty < 1)
    return qty
  }

  const handleConfirmLineEdit = (prodEdited) => {
    const { productsList } = cart
    const _productsList = productsList?.map(product => {
      if (prodEdited.id === product.id)
        return { ...prodEdited }
      return { ...product }
    })
    setCart({
      ...cart,
      productsList: _productsList
    })
  }

  const handleEditProduct = () => {
    if (localProduct.qty > 0) {
      setEditLineOpen(true)
    }
  }

  const cardClass = matches ? (zoomOnHover ? `${classes.root} ${classes.rootWithZoom}` : classes.root) : (zoomOnHover ? `${classes.rootSM} ${classes.rootSMWithZoom}` : classes.rootSM);


  return (
    <Card className={cardClass}>
      <OrderProductEdit itemToEdit={localProduct} order={cart} openForm={editLineOpen} setOpenForm={setEditLineOpen} onConfirm={handleConfirmLineEdit} />
      <CardActionArea>
        {
          product.src ?
            <CardMedia
              className={classes.media}
              image={product.src}
              title={product.name}
            />
            :
            product.img &&
            <CardMedia
              className={classes.media}
              image={product.img}
              title={product.name}
            />
        }
        <ProductInfo sale={product.sale} available={product.stock} />
        <CardContent style={{ paddingBottom: 0 }}>
          <Typography gutterBottom variant="h5" component="h2">
            {product.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {product.ingredients}
          </Typography>

          {
            product.sale ?
              <Grid container>
                <Typography className={classes.previousPrice} color="textSecondary" component="p">
                  ${product.previousPrice}
                </Typography>
                <Typography className={classes.price} color="primary" component="p">
                  ${product.price}
                </Typography>
              </Grid>

              :
              <Typography className={classes.price} color="primary" component="p">
                ${product.price}
              </Typography>
          }

        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing className={classes.cardActions}>
        <Grid container>
          <Grid item xs={12} style={{ padding: 8 }}>
            <InputNumber
              label='Seleccione cantidad'
              value={localProduct.qty}
              setValue={qty => handleChangeQty({ qty })}
              width='100%'
              disabled={!product.stock}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: 8 }}>
            <Button disabled={editDisabled} color="primary" variant="outlined" onClick={handleEditProduct} fullWidth>Cambiar ingredientes</Button>
          </Grid>
        </Grid>

        {/* <Button size="small" color="primary" onClick={handleAddProduct}>
          Añadir al carrito
        </Button>
        <AddProductToCart product={product} dialog={dialog} /> */}
      </CardActions>
    </Card>
  )
}
