import React from 'react'

export default ({ variant = 'gradient' }) => {

    if (variant === 'gradient')
        return (
            <hr
                style={{
                    width: '100%',
                    border: 0,
                    height: '1px',
                    backgroundImage: 'linear-gradient(to right, transparent, #CCC, transparent)'
                }}
            />
        )

    if (variant === 'solid')
        return (
            <hr
                style={{
                    width: '100%',
                    border: 0,
                    borderTop: '1px solid #CCC'
                }}
            />
        )

    return <hr />
}


