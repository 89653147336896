//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'

export const getRegions = () => {
    return new Promise((resolve, reject) => {
        firebase.app().firestore().collection('regions')
            .get()
            .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
            .catch(error => reject(saveError(error, 'getRegions')))
    })
}