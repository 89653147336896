import React, { useEffect, useState } from 'react'
import {
    Grid
} from '@material-ui/core'
import { getClientData, getClientPoints } from '../firebase/obj/client'
import { getClientAdresses } from '../firebase/obj/clientAddress'
import { CircularProgress } from '@material-ui/core'
import ClientForm from '../components/clientForm/public'
import { logOut } from '../firebase/login'



export const MyAccountWithData = ({ user, config }) => {
    const [client, setClient] = useState(null)
    const [addresses, setAddresses] = useState([])

    useEffect(() => {
        return loadData()

    }, [user])

    const loadData = () => {
        const { uid } = user || {}
        if (!uid)
            return
        const p1 = getClientData(uid)
        const p2 = getClientPoints(uid)
        Promise.all([p1, p2]).then(values => {
            const client = values[0]
            const clientPoints = values[1]
            setClient({ ...client, photoURL: user.photoURL, points: clientPoints.points })
            getClientAdresses(uid).then(list => setAddresses(list))
        }).catch(err => {
            setTimeout(() => loadData(user), 1000)
        })
    }


    if (client)
        return (<ClientForm logOut={logOut} selectedClient={client} addresses={addresses} config={config} />)

    return (
        <Grid container direction="column" justify="center" alignItems="center" style={{ height: '70vh' }}>
            <Grid item>
                <CircularProgress style={{ color: '#000000' }} />
            </Grid>
        </Grid>
    )
}

export default MyAccountWithData
