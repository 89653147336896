import React, { useEffect, useState, useRef } from 'react'
import {
    Grid,
    Card,
    CardContent,
    Typography,
    IconButton,
    CardMedia,
    CardActionArea,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ProductCard from '../productCard'
import useMainContext from '../../../hooks/useMainContext'
import Slider from "react-slick"


function SampleNextArrow(props) {
    const { style, onClick } = props
    return (
        <div
            style={{ ...style, zIndex: 1000, right: -25, float: 'right', top: '50%', position: 'absolute', height: '100%' }}
            onClick={onClick}
        >
            <IconButton>
                <NavigateNextIcon style={{ display: 'block' }} />
            </IconButton>
        </div >
    )
}

function SamplePrevArrow(props) {
    const { style, onClick } = props

    return (
        <div
            style={{ ...style, zIndex: 1000, left: -25, float: 'left', top: '50%', position: 'absolute', height: '100%' }}
            onClick={onClick}
        >
            <IconButton
            >
                <NavigateBeforeIcon style={{ display: 'block' }} />
            </IconButton>
        </div >
    )
}

export default function GroupCarousel({ group }) {
    const { cart, addToCart, setCart } = useMainContext()
    const containerRef = useRef()
    const [products, setProducts] = useState([])
    // const cardWidth = 345// Ancho fijo de cada tarjeta en píxeles

    // const theme = useTheme()
    // const matchesSM = useMediaQuery(theme.breakpoints.down('sm')) // Define el breakpoint que desees

    const settings = {
        dots: true,
        infinite: products.length > 1,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 2048,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: products.length < 4 ? 1 : 4,
                    infinite: products.length < 4 ? false : true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }

    useEffect(() => {
        // console.log('group', group)
        if (group?.products?.length > 0) {
            setProducts(group.products)
        }
    }, [group])

    // useEffect(() => {
    //     // console.log('products', products)
    //     calculateProductsBySlide()
    // }, [products, matchesSM])



    // const calculateProductsBySlide = () => {
    //     // console.log('calculateProductsBySlide')
    //     // console.time('calculateProductsTime')
    //     if (containerRef.current) {
    //         const containerWidth = containerRef.current.offsetWidth
    //         // console.log('containerWidth:', containerWidth)
    //         let numVisibleCards = Math.floor(containerWidth / cardWidth)
    //         if (numVisibleCards === 0) numVisibleCards = 1
    //         const newProductsBySlide = []
    //         let startIndex = 0
    //         const length = Number(products?.length) ?? 0
    //         // console.log('length:', length, 'numVisibleCards:', numVisibleCards)
    //         while (startIndex < length) {
    //             // console.log('startIndex:', startIndex)
    //             newProductsBySlide.push(products.slice(startIndex, startIndex + numVisibleCards))
    //             startIndex += numVisibleCards
    //         }
    //         setProductsBySlide(newProductsBySlide)
    //         if (products.length === 1 && matchesSM) {
    //             setProductsBySlide([products])
    //         }

    //     }
    //     // console.timeEnd('calculateProductsTime')
    // }




    if (products.length === 0) {
        return <></>
    }



    return (
        <Card style={{ maxWidth: '95vw' }}>
            {/* {
                group?.nameVisible && <CardHeader title={group?.name || ''} />
            } */}
            <CardActionArea>
                <div style={{ position: 'relative', minHeight: 120 }}>
                    {
                        group?.img &&
                        <CardMedia
                            image={group.img}
                            title={group?.name || 'imagen'}
                            style={{ height: 200 }}
                        />
                    }

                    {
                        group?.nameVisible &&
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                width: '100%',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'rgba(255, 255, 255, 0.708)',
                                padding: '8px',
                                borderRadius: '4px',
                            }}
                        >
                            <Typography
                                variant="h5"
                                style={{ textAlign: 'center', fontSize: 50, fontStyle: 'italic', color: '#333' }}
                            >
                                {group?.name || 'Título'}
                            </Typography>
                        </div>
                    }

                </div>
            </CardActionArea>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    style={{ width: '100%' }}
                >
                    <Grid item ref={containerRef} xs={12} >
                        <Slider {...settings}>
                            {products.map((prod) => (
                                <ProductCard key={prod.id} product={prod} cart={cart} setCart={setCart} addToCart={addToCart} width={'100%'} zoomOnHover={false} />
                            ))}
                        </Slider>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )

}
