//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'

export const getOrderStates = () => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('orderState')
    .get()
    .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
    .catch(error => reject(saveError(error, 'getOrderStates')))
})

export const sortSates = (a, b) => {
  const stateA = a.value //.toUpperCase()
  const stateB = b.value //.toUpperCase()

  let comparison = 0
  if (stateA > stateB) {
    comparison = 1
  } else if (stateA < stateB) {
    comparison = -1
  }
  return comparison
}