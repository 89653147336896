export const validateClient = (client) => {
    const { name = '', email = '', } = client
    let validation = {
        valid: true,
        message: '',
        severity: 'success'
    }

    if (!name) {
        validation.valid = false
        validation.message = 'Ingrese nombre de cliente'
        validation.severity = 'error'
    }

    return validation
}

export const validateClientAddress = (clientAddress) => {
    const { address = '' } = clientAddress
    let validation = {
        valid: true,
        message: '',
        severity: 'success'
    }

    return validation
}