import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import {
    getCollectionFiltered,
    getDocument,
    saveDocument,
    updateDocument,
    deleteDocument
} from './abm'
import { snapshotToArray } from '../../utils'

const collection = 'beepers'


export const beepersRealTime = (setState = () => { }) =>
    firebase.app().firestore().collection(collection)
        .orderBy('id', 'asc')
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot.docs))
        })


export const getBeeperData = id => getDocument(id, collection)

export const getAllBeepers = () => getCollectionFiltered({ collection })

export const saveBeeper = beeper => saveDocument(beeper, collection, beeper.id)

export const updateBeeper = beeper => updateDocument(beeper, collection)

export const deleteBeeper = id => deleteDocument(id, collection)