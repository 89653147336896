import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Map from '../map'

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '550px',
        overflow: 'hidden'
    },
    fullWidth: {
        width: '100%',
        margin: 0,
        padding: 0
    }
}))

export default function DialogMap({ oneButton = false, setState = () => { }, open = false, setPosition = () => { }, markerPosition = null, client = {}, regions = [], title = '', config = {}, setConfirm = () => { }, setCancel = () => { } }) {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleClose = () => {
        setState(false)
        setCancel()
    }

    const handleConfirm = () => {
        setState(false)
        setConfirm()
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{title || client.name}</DialogTitle>
            <DialogContent className={fullScreen ? classes.fullWidth : classes.root}>
                <Map config={config} showMarker={true} getPosition={setPosition} centerMap={markerPosition} markerPosition={client.position} client={client} regions={regions} />
            </DialogContent>
            {
                oneButton ?
                    <DialogActions>
                        <Button onClick={handleConfirm} color="primary" autoFocus>
                            Cerrar
                        </Button>
                    </DialogActions>
                    :
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleConfirm} color="primary" autoFocus>
                            Aplicar
                        </Button>
                    </DialogActions>
            }

        </Dialog>
    )
}
