import React from 'react'
import TermsConditionsWithData from '../containers/termsConditionsWithData'

export const TermsConditionsPage = () => {

    return (
        <TermsConditionsWithData />
    )
}

export default TermsConditionsPage