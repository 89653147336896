import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import {
    getCollectionFiltered,
    getDocument,
    saveDocument,
    updateDocument,
    deleteDocument
} from './abm'
import { snapshotToArray } from '../../utils'

const collection = 'tables'


export const tablesRealTime = (setState = () => { }) =>
    firebase.app().firestore().collection(collection)
        .orderBy('id', 'asc')
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot?.docs || []))
        })


export const getTableData = id => getDocument(id, collection)

export const getAllTables = () => getCollectionFiltered({ collection })

export const saveTable = table => saveDocument(table, collection, table.id)

export const updateTable = table => updateDocument(table, collection)

export const deleteTable = id => deleteDocument(id, collection)

export const tableHistoryOpenRealTime = (tableId, setState = () => { }) =>
    firebase.app().firestore().collection(collection).doc(tableId).collection('tableHistory')
        .where('state', '==', 'open')
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot?.docs || []))
        })

export const closeTableHistory = (tableHistory) =>
    new Promise((resolve, reject) =>
        firebase.app().firestore().collection(collection).doc(tableHistory.tableId).collection('tableHistory').doc(tableHistory.id).update(tableHistory)
            .then(ref => resolve('success'))
            .catch(error => reject(error))
    )

export const getTableHistory = (tableId, tableHistoryId) =>
    firebase.app().firestore().collection(collection).doc(tableId).collection('tableHistory').doc(tableHistoryId).get()
        .then(doc => doc.data())

export const getTableHistoryRealTime = (tableId, tableHistoryId, setState = () => { }) =>
    firebase.app().firestore().collection(collection).doc(tableId).collection('tableHistory').doc(tableHistoryId)
        .onSnapshot(doc => {
            setState(doc.data())
        })

export const updateTableHistory = (tableId, tableHistoryId, tableHistory) =>
    firebase.app().firestore().collection(collection).doc(tableId).collection('tableHistory').doc(tableHistoryId).update(tableHistory)