import React from 'react'
import {
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Typography,
    Select,
    MenuItem
} from '@material-ui/core'

export default function PaymentMethod({ paymentMethods = [], method, setMethod, component = "radiobutton", disabled }) {


    if (!paymentMethods)
        return <></>

    if (paymentMethods.length === 0)
        return <></>

    if (component === "select")
        return (
            <FormControl fullWidth>
                <FormLabel component="legend">Método de pago</FormLabel>
                <Select value={method || ''} onChange={(e) => setMethod(e.target.value)} disabled={disabled}>
                    <MenuItem value='' disabled>
                        <em>Seleccionar método de pago</em>
                    </MenuItem>
                    {
                        paymentMethods?.map(method =>
                            <MenuItem key={method.id} value={method.method}>
                                {method.name}
                            </MenuItem >
                        )
                    }
                </Select>
            </FormControl>
        )

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Método de pago</FormLabel>
            <RadioGroup aria-label="paymentMethods" name="paymentMethods" value={method || ''} onChange={(e) => setMethod(e.target.value)} disabled={disabled}>
                {
                    paymentMethods?.map(method =>
                        <FormControlLabel
                            key={method.id}
                            disabled={disabled}
                            value={method.method}
                            control={<Radio color="primary" />}
                            label={
                                <div>
                                    {method.name}
                                    <Typography variant="body2" color="textSecondary">
                                        {method.description}
                                    </Typography>
                                </div>
                            }
                        />
                    )
                }
            </RadioGroup>
        </FormControl>

    )
}
