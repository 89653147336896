import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { saveError } from './error'

export const getModules = () => {
    return new Promise((resolve, reject) => {
        firebase.app().firestore().collection('config')
            .doc('modules')
            .get()
            .then(conf => resolve(conf.data()))
            .catch(error => reject(saveError(error, 'getModules')))
    })
}